<template>
  <div class="progressLoading">
    <v-progress-circular
      color="primary"
      indeterminate
    />
    <div class="mt-4">
      loading...
    </div>
  </div>
</template>

<style lang="scss" scoped>
.progressLoading {
  text-align:center;
  position: absolute;
  top: 50%; left: 50%; transform: translate(-50%, -50%);
}
</style>
